<template>
  <v-row>
    <v-col cols="12">
      <p class="commentaire">
        <slot />
      </p>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "BaseCommentaire"
}
</script>

<style lang="scss" scoped>
.commentaire {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
}
</style>
