<template>
  <v-sheet
    class="fill-height pt-11 px-16"
    color="cassiopee-grey lighten-1"
  >
    <form-alert 
      message="Félicitations ! Vous avez rempli suffisament de données dans le formulaire pour obtenir vos résultats"
      color="cassiopee-green"
      icon="$success"
    />
    <form-alert 
      :message="`Données confidentielles à titre informatif sur la base des données communiquées concernant ${entite.nom} et établies le ${currentDate}.`"
      color="cassiopee-purple"
      centered
    />
    <div class="d-flex justify-space-around">
      <base-button
        right
        icon="$saveWhite"
        class="mt-0"
        @click="downloadSummaryDoc('full')"
      >
        Télécharger le rapport complet
      </base-button>
      <base-button
        right
        icon="$saveWhite"
        class="mt-0"
        @click="downloadSummaryDoc('simplified')"
      >
        Télécharger le rapport simplifié
      </base-button>
    </div>
    <div class="d-flex justify-center">
      <base-button
        outlined
        right
        icon="$savePurple"
        class="mt-0"
        @click="downloadSummaryDoc('digest')"
      >
        Télécharger le rapport digest
      </base-button>
    </div>
    <form-alert
      v-if="loaderToggle"
      message="Le fichier est en cours de préparation pour le téléchargement"
      color="cassiopee-orange"
      centered
    >
      <v-progress-circular
        :indeterminate="loaderToggle"
        color="cassiopee-orange"
        class="mr-4"
      />
    </form-alert>
    <v-row>
      <v-col>
        <form-step-title 
          title="Votre hébergement actuel" 
          class="mt-9 mb-8"
        />
      </v-col>
      <v-col>
        <form-step-title 
          title="Simulation Datacenter cible" 
          class="mt-9 mb-8"
        />
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <span 
        id="capacite_hebergement"
        class="anchor" 
      />
      <v-col>
        <base-card
          title="Capacité hébergement actuel"
          :commentaire="commentaireCardHebergementActuel"
          height="370"
          class="px-3 pt-3"
        >
          <v-row 
            v-for="(section,i) in sectionHebergementActuelList" 
            :key="i"
            class="mt-5"
          >
            <v-col 
              cols="2"
              class="d-flex justify-center pt-0"
            >
              <v-icon 
                size="35"
                v-text="section.icon" 
              />
            </v-col>
            <v-col 
              cols="5" 
              class="d-flex flex-column pt-0"
            >
              <span :class="`text-h3 font-weight-regular ${section.color}`">{{ section.title }}</span>
              <span class="text-subtitle-2 cassiopee-grey--text text--darken-4 font-weight-regular">
                {{ section.subtitle }}
              </span>
            </v-col>
            <v-col 
              cols="5" 
              class="d-flex flex-column pt-0"
            >
              <span class="text-subtitle-2 cassiopee-grey--text text--darken-3 mb-2">
                {{ section.capacity }}
              </span>
              <span class="text-subtitle-2 cassiopee-grey--text text--darken-3">
                {{ section.use }}
              </span>
            </v-col>
          </v-row>
          <v-row class="my-auto">
            <v-col class="d-flex justify-center">
              <base-button
                width="230"
                height="36"
                color="cassiopee-purple"
                class="my-3"
                @click="goToSiteDefinition()"
              >
                Modifier les informations
              </base-button>
            </v-col>
          </v-row>
        </base-card>  
      </v-col>
      <v-col>
        <base-card
          title="Capacité hébergement cible"
          commentaire="Simulée avec un facteur de rationnalisation"
          height="370"
          class="px-3 pt-3"
          color="cassiopee-purple lighten-7"
        >
          <v-row 
            v-for="(section,i) in sectionHebergementCibleList" 
            :key="i"
            class="mt-5"
          >
            <v-col 
              cols="2"
              class="d-flex justify-center pt-0"
            >
              <v-icon 
                size="35"
                v-text="section.icon" 
              />
            </v-col>
            <v-col 
              :cols="section.capacity ? 5 : 10" 
              class="d-flex flex-column pt-0"
            >
              <span :class="`text-h3 font-weight-regular ${section.color}`">{{ section.title }}</span>
              <span 
                v-if="section.subtitle"
                class="text-subtitle-2 cassiopee-grey--text text--darken-4 font-weight-regular"
              >
                {{ section.subtitle }}
              </span>
            </v-col>
            <v-col 
              v-if="section.capacity"
              cols="5" 
              class="d-flex align-center pt-0"
            >
              <span class="text-subtitle-2 cassiopee-grey--text text--darken-3 font-weight-regular">
                {{ section.capacity }}
              </span>
            </v-col>
          </v-row>
          <v-row class="my-auto">
            <v-col class="d-flex justify-center">
              <base-button
                width="230"
                height="36"
                color="cassiopee-purple"
                @click="goToMutualizedDatacenter"
              >
                Modifier les informations
              </base-button>
            </v-col>
          </v-row>
        </base-card>
      </v-col>
    </v-row>
    <v-row>
      <span 
        id="risque_hebergement"
        class="anchor" 
      />
      <v-col>
        <base-gauge-card 
          class="px-3 pt-3"
          height="498"
          title="Niveau de risque de l'hébergement actuel"
          :color="niveauDeRisque.color"
          :level="niveauDeRisque.niveau"
          :image="niveauDeRisque.image"
          gauge-subtitle="Calculé sur base de votre évaluation des risques et contraintes de votre hébergement actuel"
          result-title="Coût d'une interruption de service"
          result-comment="indisponibilité de l'hébergement"
          :result-number="cout_interruption_service"
        />
      </v-col>
      <v-col>
        <base-gauge-card 
          class="px-3 pt-3"
          height="498"
          title="Niveau de risque cible"
          color-text-level="cassiopee-green--text"
          :color="datacenterRisque.color"
          :level="datacenterRisque.niveau"
          :image="datacenterRisque.image"
          gauge-subtitle="Calculé sur base d'une projection d'hébergement dans le datacenter cible mutualisé"
          result-title="Taux de disponibilité du datacenter"
          result-comment="équivalence TIER selon Uptime Institute"
          :result-text="datacenter.fiabilite_tier"
        />
      </v-col>
    </v-row>
    <v-row>
      <span 
        id="impact_environnement" 
        class="anchor" 
      />
      <v-col>
        <base-card
          title="Impact environnement actuel"
          height="382"
          class="pt-3 card-impact px-3"
          icon="$ecoElecLight"
        >
          <div class="d-flex flex-column align-center text-center mt-4 cassiopee-green--text card-impact__text">
            <p class="mb-1">
              PUE = {{ pue.value }} {{ pue.flag_donnee_manquante ? '*' : '' }}
            </p>
            <p class="mb-0">
              <span>{{ resultDataAnnuel.impact_environnemental | toFormatNumber }} kg </span>
              <span class="text-body-1 font-weight-regular">co2 eq/an</span>
            </p>
          </div>
          <div
            class="d-flex align-center mt-2 text-center card-impact__bandeau--light"
            :class="{'card-impact-bg': pue.flag_donnee_manquante}"
          >
            <p
              v-if="pue.flag_donnee_manquante"
              class="text-subtitle-2 cassiopee-green--text mx-auto mb-0"
            >
              *Information générique par manque de données
            </p>
          </div>
          <v-row class="my-auto d-flex justify-center">
            <base-modal
              width="1044"
              height="637"
            >
              <template #activator="{ on, attrs }">
                <v-col>
                  <base-button
                    width="220"
                    height="36"
                    color="cassiopee-green darken-1"
                    class="my-3"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Comprendre ces chiffres
                  </base-button>
                </v-col>
              </template>
              <template #content>
                <v-carousel 
                  :show-arrows="true"
                  hide-delimiter-background
                  class="carousel-custom"
                >
                  <v-carousel-item
                    v-for="(item,i) in itemsCarousel"
                    :key="i"
                  >
                    <v-sheet
                      tile
                      class="text-center d-flex flex-column align-center"
                    >
                      <div 
                        v-if="item.textIntro"
                        class="carousel-item-content-custom"
                      >
                        <v-icon size="108">
                          {{ item.icon }}
                        </v-icon>
                        <p class="mt-7 cassiopee-purple--text text-carousel">
                          &laquo; {{ item.textIntro }} &raquo;
                        </p>
                      </div>
                      <div 
                        v-else
                        class="text-center d-flex flex-column align-center carousel-item-content-custom"
                      >
                        <span class="text-h3 cassiopee-purple--text mt-16">
                          En moyenne, l'impact environnemental de votre hébergement informatique équivaut à :
                        </span>
                        <span class="mt-5 mb-7 cassiopee-green--text text-h1">
                          {{ item.numberResultImpact | toFormatNumber }} {{ item.textResultImpact }}
                        </span>
                        <img 
                          v-if="item.image"
                          :src="item.image"
                          alt="image d'illustration"
                          class="mb-8"
                        >
                      </div>
                      <span class="carousel-item-content-custom mt-6 text-caption cassiopee-grey--text text--darken-3 ">
                        Source : {{ item.source }}
                      </span>
                    </v-sheet>
                  </v-carousel-item>
                </v-carousel>
              </template>
            </base-modal>
          </v-row>
        </base-card>
      </v-col>
      <v-col>
        <base-card
          title="Impact environnement cible"
          height="382"
          class="pt-3 px-3"
          color="cassiopee-green lighten-1"
          icon="$ecoElec"
        >
          <div class="d-flex flex-column align-center text-center mt-4 cassiopee-green--text card-impact__text">
            <p class="mb-1">
              PUE = {{ datacenter.pue }}
            </p>
            <p class="mb-0">
              <span>{{ entite.impact_datacenter_cible | toFormatNumber }} kg </span>
              <span class="text-body-1 font-weight-regular">co2 eq/an</span>
            </p>
          </div>
          <div
            class="mt-2 card-impact__bandeau text-center d-flex align-center"
            :class="{'card-impact-dark-bg': gainImpact < 0}"
          >
            <div
              v-if="gainImpact < 0"
              class="card-impact__bandeau__content mx-auto d-flex"
            >
              <v-icon 
                size="35" 
                class="mr-3"
              >
                $circleArrowGreen
              </v-icon>
              <p
                class="text-subtitle-2"
              >
                <span class="white--text">gain de </span><span class="text-h3 font-weight-regular white--text">{{ Math.round(Math.abs(gainImpact) * 100) }}%</span> par rapport à l'hébergement actuel
              </p>
            </div>
          </div>
          <v-row class="my-auto d-flex justify-center">
            <base-modal
              width="1044"
              height="637"
            >
              <template #activator="{ on, attrs }">
                <v-col>
                  <base-button
                    width="220"
                    height="36"
                    color="cassiopee-green darken-1"
                    class="my-3"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Comprendre ces chiffres
                  </base-button>
                </v-col>
              </template>
              <template #content>
                <v-carousel 
                  :show-arrows="true"
                  hide-delimiter-background
                  class="carousel-custom"
                >
                  <v-carousel-item
                    v-for="(item,i) in itemsCarouselCible"
                    :key="i"
                  >
                    <v-sheet
                      tile
                      class="text-center d-flex flex-column align-center"
                    >
                      <div 
                        v-if="item.textIntro"
                        class="carousel-item-content-custom"
                      >
                        <v-icon size="108">
                          {{ item.icon }}
                        </v-icon>
                        <p class="mt-7 cassiopee-purple--text text-carousel">
                          &laquo; {{ item.textIntro }} &raquo;
                        </p>
                      </div>
                      <div 
                        v-else
                        class="text-center d-flex flex-column align-center carousel-item-content-custom"
                      >
                        <span class="text-h3 cassiopee-purple--text mt-16">
                          En moyenne, l'impact environnemental de votre hébergement informatique équivaut à :
                        </span>
                        <span class="mt-5 mb-7 cassiopee-green--text text-h1">
                          {{ item.numberResultImpact | toFormatNumber }} {{ item.textResultImpact }}
                        </span>
                        <img 
                          v-if="item.image"
                          :src="item.image"
                          alt="image d'illustration"
                          class="mb-8"
                        >
                      </div>
                      <span class="carousel-item-content-custom mt-6 text-caption cassiopee-grey--text text--darken-3 ">
                        Source : {{ item.source }}
                      </span>
                    </v-sheet>
                  </v-carousel-item>
                </v-carousel>
              </template>
            </base-modal>
          </v-row>
        </base-card>
      </v-col>
    </v-row>
    <v-row>
      <span 
        id="couts_hebergements"
        class="anchor" 
      />
      <v-col>
        <base-chips-card 
          class="px-3 pt-3"
          height="458"
          title="Coût hébergement actuel"
          icon="$localOrange"
          subtitle="Hébergement actuel avec maintien en condition opérationnelle (MCO)"
          :chips-value="Math.round(cout_hebergement_actuel_avec_maintien * 1.2)"
          chips-color="cassiopee-orange darken-1"
          :comment="hebergementActuelComment"
          :items-list="itemsCoutHebergementActuel"
        />
      </v-col>
      <v-col>
        <base-chips-card 
          class="px-3 pt-3"
          height="458"
          title="Simulation coût hébergement cible"
          icon="$schoolPurple"
          :subtitle="datacenter.nom"
          :chips-value="Math.round(cout_datacenter_cible * 1.2)"
          chips-color="cassiopee-purple"
          comment="Comprend loyer, énergie et frais d'accès lissés sur 5 ans. hors coût de migration"
          :items-list="itemsCoutHebergementCible"
          color="cassiopee-purple lighten-7"
          :gain="gainPotentiel"
        />
      </v-col>
    </v-row>
    <v-row>
      <span 
        id="analyse_cout_hebergement_actuel"
        class="anchor" 
      />
      <v-col>
        <form-step-title 
          title="Analyse du coût de votre hébergement actuel" 
          class="mt-6 mb-6"
        />
        <base-tab-graph
          v-if="resultDataAnnuel.cout_hebergement_actuel_avec_maintien"
          class="mb-10"
          :items-list="graphTabItems"
          :title-left="tabTitle"
          :title-list-right="graphTabTitle"
          @swap="swapParentTab"
        >
          <template #left>
            <base-donut
              :series="coutHebergementGlobalSeries"
              :chart-labels="cout_hebergement_labels"
              :total-labels="coutTotalHebergement"
              :colors-palette="donutColorsPalette"
              large
              chart-height="320px"
              :treshold-value="5"
            />
          </template>
          <template #right="{nestedTab}">
            <base-donut
              :series="coutRecurrentSeries(nestedTab)"
              :chart-labels="nestedTab === 0 ? coutRecurrentLabels : coutAmortissementsLabels"
              :total-labels="formatCost(nestedTotal(nestedTab) * 1.2)"
              :colors-palette="donutColorsPalette"
              large
              chart-height="320px"
              :treshold-value="5"
            />
          </template>
        </base-tab-graph>
      </v-col>
    </v-row>
  </v-sheet>
</template>
<script>
import FormAlert from '@/components/FormAlert.vue'
import FormStepTitle from '@/components/FormStepTitle.vue'
import BaseButton from '@/components/BaseButton.vue'
import BaseCard from '@/components/BaseCard.vue'
import BaseTabGraph from '@/components/BaseTabGraph.vue'
import BaseDonut from '@/components/graph/BaseDonut.vue'
import BaseGaugeCard from '@/components/BaseGaugeCard.vue'
import BaseChipsCard from '@/components/BaseChipsCard.vue'
import BaseModal from '@/components/BaseModal.vue'
import filters from "@/mixins/filters.js"
import { mapState, mapActions, mapMutations } from 'vuex';
import { round, each, size } from 'lodash'
import numeral from 'numeral';
import { mapFields } from 'vuex-map-fields';

export default {
  name: "Results",
  components: { 
    FormAlert,
    FormStepTitle,
    BaseButton,
    BaseCard,
    BaseTabGraph,
    BaseDonut,
    BaseGaugeCard,
    BaseChipsCard,
    BaseModal,
  },
  mixins: [filters],
  data() {
    return {
      loaderToggle: false,
      currentParentTab: 'Coût hébergement annuel',
      donutColorsPalette: [
        '#16065A', 
        '#391DAC', 
        '#48A9A6', 
        '#53A8E2',
        '#76DDFB',
        '#C9E6E5',
        '#E3AC5F',
        '#E8E8F6',
        '#F7EDD4'
      ],
      graphTabItems: ['Coût hébergement annuel', 'Coût hébergement par baie', 'Coût hébergement par étudiant'],
      graphTabTitle: ['Coûts récurrents', 'MCO'],
      cout_hebergement_labels: ['Coûts récurrents',  'MCO (Maintien en Condition Opérationnelle)'],
      coutRecurrentLabels: [
        'Énergie',
        'Foncier',
        'Loyer hébergement',
        'Eau et fioul',
        'Exploitation et maintenance',
        'Gestes de proximité',
        'Coûts ponctuels',
      ],
      coutAmortissementsLabels: [
        'Maintien en condition opérationnelle (montant annuel calculé sur une période 5 ans)'
      ],
    }
  },
  computed: {
    ...mapState('form', [
      'entite',
      'resultDataAnnuel',
      'resultDataParBaie',
      'resultDataParEtudiant',
      'datacenter',
      'dashboard',
      'fullSummaryDocUrl',
    ]),
    ...mapFields('form', [
      'entite.cout_hebergement_actuel_avec_maintien',
      'entite.cout_datacenter_cible',
      'entite.gain_datacenter_cible_pourcentage',
      'formAnswer.cout_interruption_service',
      'formAnswer.cout_liaisons_datacenter',
      'entite.pue',
    ]),
    itemsCarousel() {
      return [
        {
          textIntro: 'Le saviez-vous : Le numérique a un impact de 5,2% des émissions de gaz à effet de serre en France en 2020. Sur ce total, le datacenter contribue à hauteur de 6%; Les utilisateurs à 84% et le réseau à 10%',
          icon: '$ecoElecPurple',
          source: 'Etude iNum: Impacts environnementaux du numérique en France. Green IT. Juin 2020.'
        },
        {
          numberResultImpact: this.entite.impact_equivalent.ramette_papier,
          textResultImpact: 'ramettes de papier fabriquées',
          image: './Picto-paper.svg',
          source: 'ADEME Base Carbone 2021'
        },
        {
          numberResultImpact: this.entite.impact_equivalent.kms_voiture,
          textResultImpact: 'kms parcourus en voiture',
          image: './Picto-voiture.svg',
          source: 'ADEME Base Carbone 2021'
        },
        {
          numberResultImpact: this.entite.impact_equivalent.avion_paris_new_york,
          textResultImpact: 'aller/retour avion Paris/New-York',
          image: './Picto-avion.svg',
          source: 'ADEME Base Carbone 2021'
        },
        {
          numberResultImpact: this.entite.impact_equivalent.eau_bouteille,
          textResultImpact: 'L d\'eau en bouteille',
          image: './Picto-bouteille.svg',
          source: 'ADEME Base Carbone 2021'
        },
        {
          numberResultImpact: this.entite.impact_equivalent.tgv,
          textResultImpact: 'kms en TGV pour une personne',
          image: './Picto-train.svg',
          source: 'ADEME Base Carbone 2021'
        },
        {
          numberResultImpact: this.entite.impact_equivalent.smartphone,
          textResultImpact: 'productions de smartphone',
          image: './Picto-phone.svg',
          source: 'ADEME Base Carbone 2021'
        },
        {
          numberResultImpact: this.entite.impact_equivalent.cafetiere,
          textResultImpact: 'productions de cafetière',
          image: './Picto-coffee.svg',
          source: 'ADEME Base Carbone 2021'
        },
        {
          numberResultImpact: this.entite.impact_equivalent.repas_vegetarien,
          textResultImpact: 'repas végétarien',
          image: './Picto-vegetables.svg',
          source: 'ADEME Base Carbone 2021'
        },
        {
          numberResultImpact: this.entite.impact_equivalent.repas_boeuf,
          textResultImpact: 'repas bœuf',
          image: './Picto-beef.svg',
          source: 'ADEME Base Carbone 2021'
        },
        {
          numberResultImpact: this.entite.impact_equivalent.chauffage_electrique,
          textResultImpact: 'années de chauffage électrique pour un foyer francais moyen',
          image: './Picto-heater.svg',
          source: 'ADEME Base Carbone 2021'
        },
      ];
    },
    itemsCarouselCible() {
      return [
        {
          textIntro: 'Le saviez-vous : Le numérique a un impact de 5,2% des émissions de gaz à effet de serre en France en 2020. Sur ce total, le datacenter contribue à hauteur de 6%; Les utilisateurs à 84% et le réseau à 10%',
          icon: '$ecoElecPurple',
          source: 'Etude iNum: Impacts environnementaux du numérique en France. Green IT. Juin 2020.'
        },
        {
          numberResultImpact: this.entite.impact_equivalent_cible.ramette_papier,
          textResultImpact: 'ramettes de papier fabriquées',
          image: './Picto-paper.svg',
          source: 'ADEME Base Carbone 2021'
        },
        {
          numberResultImpact: this.entite.impact_equivalent_cible.kms_voiture,
          textResultImpact: 'kms parcourus en voiture',
          image: './Picto-voiture.svg',
          source: 'ADEME Base Carbone 2021'
        },
        {
          numberResultImpact: this.entite.impact_equivalent_cible.avion_paris_new_york,
          textResultImpact: 'aller/retour avion Paris/New-York',
          image: './Picto-avion.svg',
          source: 'ADEME Base Carbone 2021'
        }
      ];
    },
    gainPotentiel() {
      return (this.cout_datacenter_cible - this.cout_hebergement_actuel_avec_maintien) / this.cout_hebergement_actuel_avec_maintien;
    },
    tabTitle() {
      return this.currentParentTab;
    },
    itemsCoutHebergementActuel() {
      return [
        {
          name: 'Coûts récurrents',
          value: Math.round(this.resultDataAnnuel.couts_recurrents * 1.2),
          unitValue: 'TTC/an',
          currencyValue: true,
        },
        {
          name: 'Coûts de maintien en condition opérationnelle (période 5 ans)',
          value: Math.round(this.resultDataAnnuel.maintien_en_condition_operationnelle * 1.2),
          unitValue: 'TTC/an',
          currencyValue: true,
        }
      ]
    },
    itemsCoutHebergementCible() {
      const items = [
        {
          name: 'hors valorisation des m² libérés',
          value: Math.round(this.resultDataAnnuel.surface_totale_calculee),
          unitValue: 'm²',
          currencyValue: false,
        }
      ];

      if (this.cout_liaisons_datacenter) {
        items.unshift({
          name: 'dont liaison au Datacenter',
          value: Math.round(this.cout_liaisons_datacenter * 1.2),
          unitValue: 'TTC/an',
          currencyValue: true,
        },)
      }

      return items;
    },
    datacenterRisque() {
      const indiceRisque = this.datacenter.niveau_de_risque;
      if (indiceRisque < 2.6) {
        return {
          niveau: 'Faible', 
          color: 'cassiopee-green--text',
          image: './graphiques/graphique-niveau-1.jpg'
        };
      } else if(indiceRisque < 4.6) {
        return {
          niveau: 'Moyen', 
          color: 'cassiopee-orange--text text--darken-2',
          image: './graphiques/graphique-niveau-2.jpg'
        };
      } else if (indiceRisque < 6.6) {
        return {
          niveau: 'Important', 
          color: 'cassiopee-orange--text text--darken-3',
          image: './graphiques/graphique-niveau-3.jpg'
        };
      } else if (indiceRisque < 8.6) {
        return {
          niveau: 'Elevé',
          color: 'cassiopee-red--text text--darken-1',
          image: './graphiques/graphique-niveau-4.jpg'
        };
      } else if (indiceRisque > 8.6) {
        return {
          niveau: 'Majeur',
          color: 'cassiopee-red--text text--darken-2',
          image: './graphiques/graphique-niveau-5.jpg'
        };
      } else {
        return {
          niveau: 'Majeur',
          color: 'cassiopee-red--text text--darken-2',
          image: './graphiques/graphique-niveau-5.jpg'
        };
      }
    },
    niveauDeRisque() {
      const indiceRisque = this.resultDataAnnuel.niveau_de_risque;

      if (indiceRisque < 2.6) {
        return {
          niveau: 'Faible', 
          color: 'cassiopee-green--text',
          image: './graphiques/graphique-niveau-1.jpg'
        };
      } else if(indiceRisque < 4.6) {
        return {
          niveau: 'Moyen', 
          color: 'cassiopee-orange--text text--darken-2',
          image: './graphiques/graphique-niveau-2.jpg'
        };
      } else if (indiceRisque < 6.6) {
        return {
          niveau: 'Important', 
          color: 'cassiopee-orange--text text--darken-3',
          image: './graphiques/graphique-niveau-3.jpg'
        };
      } else if (indiceRisque < 8.6) {
        return {
          niveau: 'Elevé',
          color: 'cassiopee-red--text text--darken-1',
          image: './graphiques/graphique-niveau-4.jpg'
        };
      } else if (indiceRisque > 8.6) {
        return {
          niveau: 'Majeur',
          color: 'cassiopee-red--text text--darken-2',
          image: './graphiques/graphique-niveau-5.jpg'
        };
      } else {
        return {
          niveau: 'Majeur',
          color: 'cassiopee-red--text text--darken-2',
          image: './graphiques/graphique-niveau-5.jpg'
        };
      }
    },
    coutTotalHebergement() {
      switch (this.currentParentTab) {
        case 'Coût hébergement annuel':
         return this.formatCost(this.resultDataAnnuel.cout_hebergement_actuel_avec_maintien * 1.2);
        case 'Coût hébergement par baie':
          return this.formatCost(this.resultDataParBaie.cout_hebergement_actuel_avec_maintien * 1.2);
        case 'Coût hébergement par étudiant':
         return this.formatCost(this.resultDataParEtudiant.cout_hebergement_actuel_avec_maintien * 1.2);
      }
      return this.formatCost(0);
    },
    coutHebergementGlobalSeries() {
      return [this.resultDataAnnuel.cout_total_hebergement * 1.2, this.entite.maintien_en_condition_operationnelle * 1.2];
    },
    sectionHebergementActuelList(){
      return [
        {
          title: 'Nombre de baies',
          subtitle: 'de votre hébergement',
          capacity: `Capacité : ${this.resultDataAnnuel.nombre_baies} baies`,
          use: `Utilisée : ${this.resultDataAnnuel.nombre_baies_calculees_total_utilise} baies`,
          icon: '$datacenterPurple',
          color: 'cassiopee-purple--text'
        },
        {
          title: 'Puissance IT',
          subtitle: 'de votre hébergement',
          capacity: `Capacité : ${this.resultDataAnnuel.puissance_actuelle} kW`,
          use: `Consommée : ${this.resultDataAnnuel.puissance_consommee} kW`,
          icon: '$flashOrange',
          color: 'cassiopee-orange--text text--darken-1'
        },
        {
          title: 'Locaux IT',
          subtitle: 'Salle informatique',
          capacity: `Nombre : ${this.resultDataAnnuel.nombre_locaux_its}`,
          use: `Surface : ${Math.round(this.resultDataAnnuel.surface_totale_salles_it)} m²`,
          icon: '$localBlue',
          color: 'cassiopee-blue--text'
        }
      ]
    },
    sectionHebergementCibleList(){
      return [
        {
          title: 'Nombre de baies',
          subtitle: 'évolutif',
          capacity: `Initiales : ${this.entite.nombre_baies_eligibles_apres_rationalisation} baies`,
          icon: '$datacenterPurple',
          color: 'cassiopee-purple--text'
        },
        {
          title: 'Puissance IT',
          subtitle: 'estimée',
          capacity: `Consommée : ${this.entite.nombre_kw_eligibles_apres_rationalisation} kW`,
          icon: '$flashOrange',
          color: 'cassiopee-orange--text text--darken-1'
        },
        {
          title: this.entite.datacenter.nom,
          icon: '$schoolBlue',
          color: 'cassiopee-blue--text'
        }
      ]
    },
    gainImpact() {
      return (this.entite.impact_datacenter_cible - this.resultDataAnnuel.impact_environnemental) / this.resultDataAnnuel.impact_environnemental;
    },
    hebergementActuelComment() {
      return `hors amortissement en cours : ${this.$options.filters.toCurrency(Math.round(this.resultDataAnnuel.amortissements))}`
    },
    tauxCompletionGlobal() {
      let value = 0;
      each(this.dashboard, element => value += element.percentage * 100)
      return Math.round(value / size(this.dashboard));
    },
    commentaireCardHebergementActuel() {
      return `Basée sur un recensement estimé complet à ${this.tauxCompletionGlobal}%`
    },
    currentDate() {
      return new Date().toLocaleDateString();
    },
  },
  created() {
    this.fetchResultDataAnnuel();
    this.fetchResultDataParBaie();
    this.fetchResultDataParEtudiant();
    this.fetchFormAnswer();
    this.fetchEntite();
    this.fetchDatacenter();
    this.fetchDashboard();
  },
  methods: {
    async downloadSummaryDoc(type) {
      this.loaderToggle = true;
      let url = '';

      if (type === 'full') {
        url = await this.fetchFullSummaryDocUrl();
      } else if (type === 'simplified') {
        url = await this.fetchSimplifiedSummaryDocUrl();
      } else if (type === 'digest') {
        url = await this.fetchDigestSummaryDocUrl();
      }

      console.log(url)
      
      if (url) {
        this.loaderToggle = false;
        const a = document.createElement('a');
        a.href = url;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    },
    goToSiteDefinition() {
      this.setCurrentStepPath("/ressources/current-host");
      this.$router.push("/ressources/site-definition");
    },
    goToMutualizedDatacenter() {
      this.setCurrentStepPath("/needs/security-referential");
      this.$router.push("/needs/mutualized-datacenter");
    },
    ...mapMutations('stepper', ['setCurrentStepPath']),
    formatCost(value) {
      return `${numeral(value).format('0.0 a')} € TTC`;
    },
    round,
    nestedTotal(nestedTab) {
      let costType = null;
      if (nestedTab === 0) {
        costType = 'couts_recurrents'
      } else {
        costType = 'maintien_en_condition_operationnelle'
      }

      switch (this.currentParentTab) {
        case 'Coût hébergement annuel':
          return this.resultDataAnnuel[`${costType}`];
        case 'Coût hébergement par baie':
          return this.resultDataParBaie[`${costType}`];
        case 'Coût hébergement par étudiant':
          return this.resultDataParEtudiant[`${costType}`];
      }
    },
    swapParentTab(tab) {
      this.currentParentTab = tab;
    },
    ...mapActions('form', [
      'fetchResultDataAnnuel',
      'fetchResultDataParBaie',
      'fetchResultDataParEtudiant',
      'fetchEntite',
      'fetchFormAnswer',
      'fetchDatacenter',
      'fetchDashboard',
      'fetchFullSummaryDocUrl',
      'fetchSimplifiedSummaryDocUrl',
      'fetchDigestSummaryDocUrl',
    ]),
    coutRecurrentSeries(nestedTab) {

      return nestedTab === 0 ? [
        this.resultDataAnnuel.energie * 1.2,
        this.resultDataAnnuel.recurrent_foncier * 1.2,
        this.resultDataAnnuel.loyer_hebergement * 1.2,
        this.resultDataAnnuel.eau_fioul * 1.2,
        this.resultDataAnnuel.exploitation_et_maintenance * 1.2,
        this.resultDataAnnuel.gestes_de_proximite * 1.2,
        this.resultDataAnnuel.couts_ponctuels * 1.2,
      ] : [this.entite.maintien_en_condition_operationnelle * 1.2];
    },
  },
  
}
</script>

<style lang="scss">
@import '@/styles/variables';

.card-impact {
  &__text {
    font-size: 35px;
  }
  &__bandeau {
    height: 66px;
    width: 100%;

    &--light {
      height: 66px;
      width: 100%;
    }

    &__content {
      max-width: 214px;
      max-height:35px;
    }
  }
}

.card-impact-bg {
  background-color: rgba(201,230,229, .44);
}

.card-impact-dark-bg {
  background-color: rgba(72,169,166, .44);
}

.anchor{
  display: block;
  height: 100px;
  margin-top: -100px;
  visibility: hidden;
}

.img-size {
  max-height: 116px;
  max-width: 170px;
}
</style>