<template>
  <v-card 
    v-bind="$attrs"
    flat
    class="mt-3"
  >
    <v-card-title class="text-h4 pt-6 font-weight-regular">
      {{ title }}
    </v-card-title>
    <div class="d-flex flex-column align-center justify-center mt-8 card-cout-hebergement">
      <v-icon
        size="35"
        class="mb-4"
      >
        {{ icon }}
      </v-icon>
      <span class="text-center mb-4 card-cout-hebergement__subtitle text-h3 font-weight-regular">
        {{ subtitle }}
      </span>
      <v-chip
        class="d-flex justify-center align-center text-h2 mb-5 px-8 py-3"
        :color="chipsColor"
        text-color="white"
      > 
        {{ chipsValue | toCurrency }} TTC/an
      </v-chip>
      <div 
        v-for="item in itemsList"
        :key="item.name"
        class="text-center text-subtitle-2 card-cout-hebergement__items mb-1"
      >
        <div
          v-if="item.currencyValue"
        >
          {{ item.name }} : <span class="cassiopee-purple--text"> {{ item.value | toCurrency }} {{ item.unitValue }}</span>
        </div>
        <div
          v-else
        >
          {{ item.name }} : <span class="cassiopee-purple--text"> {{ item.value }} {{ item.unitValue }}</span>
        </div>
      </div>
      <div 
        v-if="gain < 0"
        class="mt-5 card-cout-hebergement__bandeau text-center"
      >
        <div class="d-flex card-cout-hebergement__bandeau__content mx-auto mt-4">
          <v-icon 
            size="35" 
            class="mr-3"
          >
            $circleArrowPurple
          </v-icon>
          <p class="text-subtitle-2">
            <span class="white--text">gain de {{ Math.round(Math.abs(gain) * 100) }}%</span> par rapport à l'hébergement actuel
          </p>
        </div>
      </div>
      <base-commentaire class="font-italic card-cout-hebergement__comment text-center">
        {{ comment }}
      </base-commentaire>
    </div>
  </v-card>
</template>

<script>
import filters from "@/mixins/filters.js"
import BaseCommentaire from '@/components/BaseCommentaire.vue'

  export default {
    name: "BaseChipsCard",
    components: {
      BaseCommentaire
    },
    mixins: [filters],
    props: {
      title: {
        type: String,
        required: true
      },
      icon: {
        type: String,
        default: ""
      },
      subtitle: {
        type: String,
        default: ""
      },
      chipsColor: {
        type: String,
        required: true
      },
      chipsValue: {
        type: Number,
        default: 0
      },
      gain: {
        type: Number,
        default: 0
      },
      comment: {
        type: String,
        default: ""
      },
      itemsList: {
        type: Array,
        default: () => []
      },
    }
  }
</script>

<style lang="scss" scoped>
.card-cout-hebergement {
  &__subtitle {
    max-width: 296px;
  }
  &__items {
    max-width: 280px;
  }
  &__bandeau {
    height: 66px;
    width: 100%;
    background-color: rgba(73, 43, 196, .44);

    &__content {
      max-width: 207px;
      max-height:36px;
    }
  }
  &__comment {
    position: absolute;
    bottom: 0 !important;
    max-width: 302px;
  }
}
</style>