<template>
  <v-card
    flat
    v-bind="$attrs"
    class="d-flex flex-column rounded-lg"
    :class="{ 'd-flex align-center': centered }"
  >
    <v-row no-gutters>
      <v-col 
        no-gutters 
        class="d-flex justify-space-between"
      >
        <v-card-title
          class="text-h4 text-break pt-6 pb-0 font-weight-regular black--text align-start"
          :class="{ 'text-center': centered }"
        >
          {{ title }}
        </v-card-title>
        <v-icon
          v-if="icon"
          size="65"
          class="mt-6 mr-8"
        >
          {{ icon }}
        </v-icon>
      </v-col>
    </v-row>
    <base-commentaire 
      v-if="commentaire" 
      class="font-italic ml-1 pt-0 mt-0"
    >
      {{ commentaire }}
    </base-commentaire>
    <div 
      class="mb-2"
      :class="{ 'd-flex justify-center': slotCentered }"
    >
      <slot />
    </div>
  </v-card>
</template>

<script>
import filters from "@/mixins/filters.js"
import BaseCommentaire from '@/components/BaseCommentaire.vue'
export default {
  name: "BaseCard",
  components: {
    BaseCommentaire
  },
  mixins: [filters],
  props: {
    icon: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    commentaire: {
      type: String,
      default: ""
    },
    centered: {
      type: Boolean,
      default: false,
    },
    slotCentered: {
      type: Boolean,
      default: false
    },
    chipsColor: {
      type: String,
      default: ""
    },
    chipsValue: {
      type: Number,
      default: 0
    },
    showChips: {
      type: Boolean,
      default: false,
    },
  }
}
</script>

<style lang="scss" scoped>
.chips-number{
  height: 45px;
  width: 186px;
}
</style>