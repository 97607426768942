<template>
  <v-card 
    v-bind="$attrs"
    flat
    class="mt-3"
  >
    <v-card-title class="text-h4 pt-6 font-weight-regular black--text">
      {{ title }}
    </v-card-title>
    <div class="d-flex flex-column align-center justify-center mt-12">
      <span 
        class="card__maxi-title mb-7"
        :class="colorTextLevel"
      >
        {{ level }}
      </span>
      <img 
        :src="image"
        alt="Jauge du niveau de risque"
        class="img-size mb-7"
      >
      <base-commentaire class="card__comment text-center">
        {{ gaugeSubtitle }}
      </base-commentaire>
      <span class="cassiopee-purple--text mb-1">
        {{ resultTitle }}
      </span>
      <base-commentaire class="font-italic">
        {{ resultComment }}
      </base-commentaire>
      <span 
        v-if="resultNumber"
        class="cassiopee-purple--text card__result"
      >
        {{ resultNumber | toCurrency }} / jour
      </span>
      <span 
        v-else
        class="cassiopee-purple--text card__result"
      >
        {{ resultText }}
      </span>
    </div>
  </v-card>
</template>

<script>
import filters from "@/mixins/filters.js"
import BaseCommentaire from '@/components/BaseCommentaire.vue'

export default {
  name: "BaseGaugeCard",
  components: {
    BaseCommentaire
  },
  mixins: [filters],
  props: {
    title: {
      type: String,
      required: true
    },
    colorTextLevel: {
      type: String,
      required: true
    },
    level: {
      type: String,
      required: true
    },
    image: {
      type: String,
      required: true
    },
    gaugeSubtitle: {
      type: String,
      default: ""
    },
    resultComment: {
      type: String,
      default: ""
    },
    resultNumber: {
      type: Number,
      default: 0
    },
    resultText: {
      type: String,
      default: ""
    },
    resultTitle : {
      type: String,
      default: ""
    }
  }
}
</script>

<style lang="scss" scoped>
.card { 
  &__maxi-title {
    font-size: 35px;
    line-height: 44px;
  }

  &__subtitle {
    font-size: 12px;
    font-weight: bold;
  }

  &__comment {
    max-width: 264px;
  }

  &__result {
    font-size: 29px;
    line-height: 44px;
  }
}
</style>