<template>
  <v-card class="tab-graphique">
    <v-tabs
      v-model="tab"
      background-color="cassiopee-purple"
      slider-color="cassiopee-purple"
      grow
      height="83px"
      active-class="active-custom-class"
    >
      <v-tab
        v-for="item in itemsList"
        :key="item"
        class="tab-graphique-items text-none white--text text-h4"
        @click="$emit('swap', item)"
      >
        {{ item }}
      </v-tab>
    </v-tabs>
    <v-tabs-items
      v-model="tab"
    >
      <v-tab-item
        v-for="item in itemsList"
        :key="item"
      >
        <v-card
          color="cassiopee-purple lighten-3"
          flat
          height="479"
        >
          <v-row class="pt-12 pb-14">
            <v-col class="px-10 d-flex align-center justify-center flex-column">
              <v-card-title class="pt-2 text-h4 cassiopee-purple--text title-left pb-1">
                {{ titleLeft }}
              </v-card-title>
              <div class="cassiopee-purple lighten-3 pt-10">
                <slot
                  name="left"
                />
              </div>
            </v-col>
            <v-divider 
              vertical
              class="cassiopee-purple lighten-4"  
            />
            <v-col class="px-10">
              <v-tabs
                v-model="titleTab"
                background-color="transparent"
                slider-color="cassiopee-purple"
                grow
                height="35px"
                class="pt-0"
              >
                <v-tab
                  v-for="title in titleListRight"
                  :key="title"
                  class="text-h4 text-none cassiopee-purple--text text--lighten-5"
                  active-class="cassiopee-purple--text"
                >
                  {{ title }}
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="titleTab">
                <v-tab-item
                  v-for="title in titleListRight"
                  :key="title"
                >
                  <div class="cassiopee-purple lighten-3 d-flex justify-center align-center pt-10">
                    <slot
                      name="right"
                      :nested-tab="titleTab"
                    />
                  </div>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
  export default {
    name: "BaseTabGraph",
    props: {
      itemsList: {
        type: Array,
        required: true
      },
      titleLeft: {
        type: String,
        default: ""
      },
      titleListRight: {
        type: Array,
        default: () => []
      },
    },
    data () {
      return {
        tab: null,
        titleTab: null,
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      }
    },
  }
</script>

<style lang="scss">
@import '@/styles/variables';

.tab-graphique{
  
  .active-custom-class {
    background: map-deep-get($colors, 'cassiopee-purple', 'darken-1');
    opacity: 1 !important;
    box-shadow: 0px 1px 8px rgba(14, 14, 14, 0.5);
  }

  &.items {
    opacity: 0.33;
  }

  .title-left {
    border-bottom: 2px solid map-deep-get($colors, 'cassiopee-purple', 'base');
  }
}


</style>