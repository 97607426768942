<template>
  <div class="app-chart">
    <apexchart 
      :options="chartOptions" 
      :series="seriesAboveThreshold"
      :height="chartHeight"
      :width="chartWidth"
    />
  </div>
</template>

<script>
import { round, sum } from 'lodash'
export default {
  name: "BaseDonut",
  props: {
    series: {
      type: Array,
      required: true,
    },
    chartLabels: {
      type: Array,
      required: true,
    },
    totalLabels: {
      type: String,
      default: ""
    },
    chartHeight: {
      type: String,
      default: "auto"
    },
    chartWidth: {
      type: String,
      default: "100%"
    },
    large: {
      type: Boolean,
      default: false
    },
    colorsPalette: {
      type: Array,
      required: true
    },
    tresholdValue: {
      type: Number,
      default: undefined
    }
  },
  computed: {
    chartOptions() {
      return { 
        labels: this.newChartLabels,
        chart: {
          type: 'donut'
        },
        legend: {
          position: 'bottom',
          fontSize: 12,
          fontWeight: 600,
          fontFamily: 'Josefin Sans',
          horizontalAlign: 'center', 
          labels: {
            colors: '#391DAC',
          },
          itemMargin: {
            vertical: 5,
          },
          onItemClick: {
            toggleDataSeries: false
          }
        },
        colors: this.colorsPalette,
        tooltip: {
          fillSeriesColor: false,
          theme: 'light',
          style: {
            fontSize: '11px',
            fontFamily: 'Josefin Sans'
          },
          y: {
            formatter: (value) => round(value, 0) + " € TTC"
          },   
        },
        stroke: {
          show: false
        },
        dataLabels: {
          enabled: true,
          formatter: (value) => round(value, 0) + "%",
          textAnchor: 'middle',
          style: {
            fontSize: this.large ? '15px' : '12px',
            fontFamily: 'Josefin Sans',
            fontWeight: 400,
            colors: this.newColorDataLabels
          },
          dropShadow: {
            enabled: false
          }
        },
        plotOptions: {
          pie: {
            donut: {
              size: '57%',
              labels: {
                show: true,
                name: {
                  show: true,
                  offsetY: 7
                },
                value: {
                 show: false
                },
                total: {
                  show: true,
                  showAlways: true,
                  label: this.totalLabels,
                  fontFamily: 'Josefin Sans',
                  fontSize: this.large ? '17px' : '14px',
                  fontWeight: 700,
                  color: '#492BC4'
                }
              }
            }
          }
        },
      }
    },
    newChartLabels() {
      const total = sum(this.series);
      const newChartLabels = []
      let other = false;
      this.chartLabels.forEach((element, index) => {
        if ((this.series[index]/total) > (this.tresholdValue / 100)) {
          newChartLabels.push(element);
        } else {
          other = true;
        }
      })
      if (other) newChartLabels.push('Autre')
      return newChartLabels
    },
    newColorDataLabels() {
      let newDataLabelsColors = this.colorsPalette.map(
        color => (color == '#E8E8F6' || color =='#F7EDD4' || color =='#C9E6E5') ? '#492BC4' : '#FFFFFF'
        )
      return newDataLabelsColors
    },
    seriesAboveThreshold() {
      const total = sum(this.series);
      const newSeries = []
      let other = 0;
      this.series.forEach(element => {
        if ((element/total) > (this.tresholdValue / 100)) {
          newSeries.push(element);
        } else {
          other += element;
        }
      })

      if (other > 0) newSeries.push(other)
      return newSeries
    },
  },
}
</script>

<style lang="scss">
.app-chart {
  cursor: pointer;
  & .apexcharts-tooltip {
  color: white !important;
  background: #301699 !important;
  border: 1px solid #301699 !important;
  }
}
</style>